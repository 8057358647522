<template>
<div>
    <CCard class="col-lg-6 col-sm-12">
      <CCardHeader>
        Relay List
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <div class="position-relative">
              <CDataTable
                hover
                small
                fixed
                table-filter
                sorter
                items-per-page-select
                :items-per-page="15"
                :fields="relayListFields"
                :items="relayListItem"
                :noItemsView="{ noItems: 'No Item~' }"
                pagination
              >
                <template #source="{item}">
                  <td class="py-2">
                    <CCardText><CIcon :name='"cif-"+item.srcFlag' /> {{ item.src.name }}</CCardText>
                  </td>
                </template>
                <template #to>
                  <td class="py-2">
                    <CCardText>=></CCardText>
                  </td>
                </template>
                <template #destination="{item}">
                  <td class="py-2">
                    <CCardText><CIcon :name='"cif-"+item.dstFlag' />{{ item.dst.name }}</CCardText>
                  </td>
                </template>
                <template #action="{item}">
                  <td class="py-2">
                    <CButton color="primary" disabled>Change Destination</CButton>
                  </td>
                </template>
              </CDataTable>
              <CElementCover
                :boundaries="[{ sides: ['top', 'left'], query: '.media-body' }]"
                :opacity="1"
                v-if="relayLoading"
              >
                <h1 class="d-inline">Loading... </h1>
                <CSpinner size="2xl" color="success" />
              </CElementCover>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CToaster :autohide="3000">
      <template v-for="toast in fixedToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
          :color="alertLevel"
          header="Alert"
        >
          {{ returnMsg }}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
export default {
  name: "Relay",
  data() {
    const relayListFields = [
      { key: "source", label: "Access Point" },
      { key: "to", label: "" },
      { key: "destination", label: "Destination" },
      { key: "action", label: "Action"}
    ];
    return {
      relayLoading: true,
      fixedToasts: 0,
      returnMsg: "",
      alertLevel: "success",
      relayListFields,
      relayListItem: [],
      nodeInfoModal: false
    };
  },
  mounted: function() {
    this.onLoadPage();
  },
  methods: {
    onLoadPage: function() {
      this.$axios.get("user/relay/list", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.relayListItem = response.data.relayList.map(item => {
              let srcRemark = item.src.status.split("#");
              let dstRemark = item.dst.status.split("#");
              return {
                ...item,
                srcFlag: srcRemark[0],
                dstFlag: dstRemark[0]

              };
            });
            this.relayLoading = false;
          }
        });
    }
  }
};
</script>